.car-product-art, .car-product-cross {
  ul {
    list-style: none;
    padding-left: 5px;
    color: gray;
    li {
      padding: 4px 0;
      color: black;
    }
  }
}

.category-item {
  .thumbnail > img, .thumbnail a > img {
    max-width: 80%;
  }
}
