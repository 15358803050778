/*------------------------------------------*/
/*	 INTRO REGISTER FORM
/*------------------------------------------*/
.breadcrumb-area .form {
    /*background-color: rgba(20, 20, 20, 0.65);*/
    /*border: 1px solid #333;*/
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    /*padding: 30px 10px 40px;*/
    margin: 0;
}

.breadcrumb-area h4 {
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.breadcrumb-area p {
    color: #fff;
    line-height: 20px;
    margin-bottom: 30px;
    padding: 0 10px;
}

/*------------------------------------------*/
/*	  Register Form Input
/*------------------------------------------*/
.breadcrumb-area .form-control {
    height: 44px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

input[type="text"],
input[type="email"],
textarea {
    background-color: #f0f0f0;
    color: #257e9e;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.form-control:focus {
    background-color: #f0f0f0;
    border-color: #52b3d7;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px #52b3d7, 0 0 3px #52b3d7;
    box-shadow: inset 0 1px 1px #52b3d7, 0 0 3px #52b3d7;
}

/*------------------------------------------*/
/*	   Register Form Error Message
/*------------------------------------------*/
.error {
    color: #fc0000;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 20px;
}

/*# sourceMappingURL=form.css.map */
