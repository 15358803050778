.padding-top-10 {
  margin-top: 10%;
}

.padding-top-10px {
  margin-top: 10px;
}

.padding-top-20px {
  margin-top: 20px;
}

.padding-top-30px {
  padding-top: 30px;
}

.padding-top-80px {
  padding-top: 80px;
}

.section-padding-20px {
  padding: 20px 0 20px;
}

.section-padding-50px {
  padding: 50px 0 50px;
}

.section-padding-10em {
  padding: 10em 0 10em;
}

.section-padding {
  padding: 108px 0 110px;
}

.padding-bottom-20px {
  padding-bottom: 20px;
}

.padding-bottom-30px {
  padding-bottom: 30px;
}

.margin-horizontal-10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-top-20px {
  margin-top: 20px;
}

.margin-top-30px {
  margin-top: 30px;
}

.margin-top-60px {
  margin-top: 60px;
}

.margin-top-70px {
  margin-top: 70px;
}

.margin-top-100px {
  margin-top: 100px;
}

.margin-top-5 {
  margin-top: 5%;
}

.margin-top-10 {
  margin-top: 10%;
}

.margin-top-25 {
  margin-top: 25%;
}

.margin-bottom {
  margin-bottom: 50px;
}

.margin-bottom-10px {
  margin-bottom: 7px;
}

.margin-bottom-20px {
  margin-bottom: 17px;
}

.margin-bottom-xs {
  margin-bottom: 30px;
}

.margin-right {
  margin-right: 50px;
}

.margin-left {
  margin-left: 50px;
}
