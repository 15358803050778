.breadcrumb-area {
  background: rgba(0, 0, 0, 0.5);
  padding: 35px 5px;
  max-width: 555px;
  margin: 0px auto;

  .line-dec {
    margin: 15px 0 20px;
    width: 40px;
    height: 2px;
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
  }

  h2, p {
    &:last-child {
      margin: 0;
    }
  }

  h5 {
    margin-bottom: 30px;
  }

  p a {
    color: #fff;
    margin: 0px 7px;
    text-decoration: none;
  }

  &.banner-slider-inner-1 {
    max-width: 100%;
    @media (max-width: 768px) {
      background: transparent;
    }
  }

}
