/** MAIN-HEADER AREA START **/
.main-header {
  background: white;

  .navbar-default .nav > li > a {
    display: block;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #686868;
  }

  .navbar-default .nav > li > a:hover {
    background: #f5f5f5;
    color: $color-light;
    border: none;
  }

  .navbar-default .nav > li > .active {
    color: $color-light;
    background: #f5f5f5;
  }

  .navbar-default .nav {
    margin: 23px 0;
  }

  @media (min-width: 996px) {
    .navbar-default .nav {
      margin-top: 29px;
    }
  }

}

.navbar-default {
  background: none;
  border: none;
  margin-bottom: 0px;
  border-radius: 0px;
}

.navbar-brand-logo {
  float: left;
  padding: 29px 0px;
}

.navbar-brand-logo img {
  height: 33px;
}

.dropdown-menu > li > a {
  display: block;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 12px;
  border-left: solid 5px white;
  border-bottom: solid 1px white;
  background: none;
}

.dropdown-menu > li > a:hover {
  border-left: solid 5px $color;
  background: none;
  border-bottom: solid 1px #f1f1f1;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: #f5f5f5;
  border-color: #ffffff;
}

@media (max-width: 768px) {
  .navbar-brand-logo {
    max-width: calc(100% - 44px);
  }
  .navbar-brand-logo img {
    height: 100%;
    width: auto;
    max-height: 23px;
    max-width: calc(100% - 20px);
  }

  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    //margin-top: 32px;
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0px;
  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .navbar-brand-logo img {
    display: none;
  }
}
