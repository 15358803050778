/**PAGE BANNER AREA START **/
.page-banner {
  width: 100%;
  background-image: url("/resources/img/banner/banner-slider-01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 160px 0px;
  text-align: center;
  color: white;
}

/** BANNER AREA START **/
.banner {
  width: 100%;
  color: white;
  .item {
    height: 546px;
  }
}

.item-1 {
  background: url("/resources/img/slider/slide0.jpg");
  background-size: cover;
  background-position: 20% 50%;
}

.item-2 {
  background: url("/resources/img/slider/slide1.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 10% 50%;
}

.carousel-control {
  &.left, &.right {
    background: none;
  }
}

.slider-mover {

  &-left {
    width: 40px;
    height: 40px;
    line-height: 37px;
    position: absolute;
    top: 43%;
    z-index: 5;
    display: inline-block;
    left: 5px;
    background: #fff;

    img {
      width: 16px;
      height: 23px;
    }
  }

  &-right {
    width: 40px;
    height: 40px;
    line-height: 37px;
    position: absolute;
    top: 43%;
    z-index: 5;
    display: inline-block;
    right: 5px;
    background: #fff;

    img {
      width: 16px;
      height: 23px;
    }

  }
}

.banner-slider-inner-1 {
  position: relative;
  top: 23%;

  .Website-text {
    font-family: 'Ubuntu', cursive;
    font-size: 60px;
  }

  h1 {
    color: #f76e39;
    font-family: "Sofachrome Rg", "Ubuntu", sans-serif;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 900;
    text-shadow: 2px 2px 2px #000;
    span {
      color: #017cc3;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 40px;
    text-shadow: 2px 2px 2px #000;
  }

  h4 {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-shadow: 2px 2px 2px #000;
  }

  span {
    font-weight: 300;
    @media (max-width: 768px) {
      font-weight: 500;
    }
    text-shadow: 2px 2px 2px #000;
  }
}

@media (max-width: 768px) {
  .page-banner {
    padding: 30px 0px;
  }

  .banner-slider-inner-1 {
    position: relative;
    /*position: absolute;*/
    top: 23%;
    text-align: center;
    padding: 0px 20px 0px 20px;
  }

  .banner-slider-inner-1 h1 {
    font-size: 27px;
  }

  .banner-slider-inner-1 h2 {
    font-size: 18px;
  }

  .banner-slider-inner-1 .Website-text {
    font-size: 25px;
  }

  .banner-slider-inner-1 h4 {
    font-size: 15px;
    line-height: 20px;
  }
}

@media (max-width: 460px) {
  .banner-slider-inner-1 {
    h1 {
      font-size: 20px;
    }
  }
}
